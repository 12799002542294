var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.getOffers,"sort-by":"offer_id","items-per-page":100,"height":"75vh","footer-props":_vm.footerProps,"fixed-header":"","search":_vm.search,"custom-filter":_vm.customSearch,"sort-desc":""},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Offerten-Übersicht")]),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green","dark":""},on:{"click":_vm.newOffer}},[_vm._v("Neue Offerte")])],1)]},proxy:true},{key:"header.offer_id_formatted",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterNumber ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"number","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterNumber),callback:function ($$v) {_vm.$set(_vm.filters, "filterNumber", $$v)},expression:"filters.filterNumber"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterNumber = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterType ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-select',{staticClass:"pa-4",attrs:{"type":"number","label":"Filter","items":['normal', 'email'],"autofocus":true},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.getIconForType(item))+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.getIconForType(item))+" ")])]}}],null,true),model:{value:(_vm.filters.filterType),callback:function ($$v) {_vm.$set(_vm.filters, "filterType", $$v)},expression:"filters.filterType"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterType = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterName ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterName),callback:function ($$v) {_vm.$set(_vm.filters, "filterName", $$v)},expression:"filters.filterName"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterName = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.customer",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterCustomer ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterCustomer),callback:function ($$v) {_vm.$set(_vm.filters, "filterCustomer", $$v)},expression:"filters.filterCustomer"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterCustomer = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.customer_contact",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterContact ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterContact),callback:function ($$v) {_vm.$set(_vm.filters, "filterContact", $$v)},expression:"filters.filterContact"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterContact = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.user",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterUser ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterUser),callback:function ($$v) {_vm.$set(_vm.filters, "filterUser", $$v)},expression:"filters.filterUser"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterUser = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.last_editor",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterLastEditor ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterLastEditor),callback:function ($$v) {_vm.$set(_vm.filters, "filterLastEditor", $$v)},expression:"filters.filterLastEditor"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterLastEditor = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.date",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterDate ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Filter","autofocus":true},model:{value:(_vm.filters.filterDate),callback:function ($$v) {_vm.$set(_vm.filters, "filterDate", $$v)},expression:"filters.filterDate"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterDate = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters.filterStatus ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-select',{staticClass:"pa-4",attrs:{"type":"number","label":"Filter","items":_vm.getUniqueStatuses,"autofocus":true},model:{value:(_vm.filters.filterStatus),callback:function ($$v) {_vm.$set(_vm.filters, "filterStatus", $$v)},expression:"filters.filterStatus"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.filters.filterStatus = ''}}},[_vm._v("Entfernen")])],1)])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'email')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" space_dashboard ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" functions ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date).toLocaleString('de-CH', _vm.dateOptions))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"100%"},attrs:{"color":_vm.getColorForStatus(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-speed-dial',{attrs:{"direction":"left","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v(" mdi-menu ")])],1)]},proxy:true}],null,true)},[(item.status === 'Entwurf' || _vm.hasManufacturerRights)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v(" edit ")])],1):_vm._e(),(item.type !== 'email')?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.pdf(item.offer_id)}}},[_c('v-icon',[_vm._v(" picture_as_pdf ")])],1):_vm._e(),(item.type !== 'email')?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.download(item.offer_id)}}},[_c('v-icon',[_vm._v(" cloud_download ")])],1):_vm._e(),(item.status === 'Entwurf' || _vm.hasManufacturerRights)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item.offer_id)}}},[_c('v-icon',[_vm._v(" delete ")])],1):_vm._e()],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"300px"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Angebot löschen")]),_c('v-card-text',[_vm._v("Soll das Angebot wirklich gelöscht werden?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.proceedToDelete}},[_vm._v("Bestätigen")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }