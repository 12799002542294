import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store, { formatOfferId } from './store';
import VueCookies from 'vue-cookies';
import VueAxios from 'vue-axios';
import axios, { AxiosError, AxiosResponse } from 'axios';
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueAuthenticate from 'vue-authenticate';
import { formatNumber, round } from '@/filters';

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
};

Vue.use(VueHtmlToPaper, options);
Vue.use(VueCookies);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

axios.defaults.baseURL = '';
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    let status = (<AxiosResponse>error.response).status;
    if (status === 401) {
      store.commit('logoutUser');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */

Vue.filter('formatOfferId', formatOfferId);

Vue.filter('round', round);

Vue.filter('formatNumber', formatNumber);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
