





















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import STLViewer from '@/components/util/STLViewer.vue';
import B64ImageViewer from '@/components/util/ImageViewer.vue';
import { CountersinkInfo, CustomerPartEntry, Finish, ThicknessInfo, ThreadInfo } from '@/customer/part_details';
import MaterialSelect, { MatThick } from '@/components/calculator/MaterialSelect.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcBend from '@/components/calculator/CalcBend.vue';
import CalcThread from '@/components/calculator/CalcThread.vue';
import CalcCountersink from '@/components/calculator/CalcCountersink.vue';

@Component({
  components: {
    CalcCountersink,
    CalcThread,
    CalcBend,
    za3dvisualizer: STLViewer,
    za2dvisualizer: B64ImageViewer,
    materialSelect: MaterialSelect
  }
})
export default class CalcPropertiesItem extends Vue {
  @Prop({ type: Object, required: true })
  part!: CustomerPartEntry;

  visualizer: string = 'za2dvisualizer';
  visualizerData = { uuid: this.part.uuid };

  selectedMaterial: string = this.part.material ? this.part.material : '';
  thickness_info: ThicknessInfo = this.part.thickness_info ? this.part.thickness_info : new ThicknessInfo();
  bends: any[] = this.part.bends ? this.part.bends : [];
  countersinks: any[] = this.part.countersinks ? this.part.countersinks : [];
  threads: any[] = this.part.threads ? this.part.threads : [];

  no_finishes_text = this.defaultNoFinishesText();

  availableFinishes: Array<Finish> = [];
  availableBends: number = 0;
  showDialogAfterNumBends: number = 3;
  availableThreads: Array<ThreadInfo> = [];
  availableCountersinks: Array<CountersinkInfo> = [];

  finish: Finish | null = this.part.finish;
  bendInfoDialog: boolean = false;
  bendInfoNotShown: boolean = true;

  updateAvailabilities(replace = true) {
    this.finishes(replace);
    this.updateAvailableBends(replace);
    this.updateAvailableThreads(replace);
    this.updateAvailableCountersinks(replace);
    if (replace) {
      this.updatePartInformation();
    }
  }

  @Watch('selectedMaterial')
  onSelectedMaterialChange(val: any, oldVal: any) {}

  @Watch('thickness_info', { deep: true })
  onThicknessChange(val: any, oldVal: any) {}

  @Watch('finish', { deep: true })
  onFinishChange(val: any, oldVal: any) {
    this.updatePartInformation();
  }

  defaultNoFinishesText() {
    return 'Zuerst Material und Dicke wählen';
  }

  finishes(replace = true) {
    if (replace && (!this.selectedMaterial || !this.thickness_info || this.thickness_info.thickness <= 0)) {
      this.availableFinishes = [];
      this.finish = null;
      return;
    }
    axios
      .post(`${BACKEND_URL}/available_finishes`, {
        uuid: this.part.uuid,
        num: 1,
        material: this.selectedMaterial,
        thickness: this.thickness_info
      })
      .then(response => {
        this.availableFinishes = response.data;
        let needToSet = true;
        if (this.availableFinishes.length > 0) {
          for (let f of this.availableFinishes) {
            if (this.finish?.lc_code === f.lc_code) {
              this.finish = f;
              needToSet = false;
              break;
            }
          }
          if (needToSet) {
            this.finish = this.availableFinishes[0];
          }
        }
      });
  }

  updateAvailableBends(replace = true) {
    this.availableBends = -1;
    if (replace) {
      this.bends = [];
    }
    axios
      .post(`${BACKEND_URL}/available_bends`, {
        uuid: this.part.uuid,
        material: this.selectedMaterial,
        thickness: this.thickness_info
      })
      .then(response => {
        this.availableBends = response.data['n_available_bends'];
      });
  }

  updateAvailableThreads(replace = true) {
    this.availableThreads = [];
    if (replace) {
      this.threads = [];
    }
    axios
      .post(`${BACKEND_URL}/available_threads`, {
        uuid: this.part.uuid,
        thickness: this.thickness_info
      })
      .then(response => {
        this.availableThreads = response.data;
      });
  }

  updateAvailableCountersinks(replace = true) {
    this.availableCountersinks = [];
    if (replace) {
      this.countersinks = [];
    }
    axios
      .post(`${BACKEND_URL}/available_countersinks`, {
        uuid: this.part.uuid,
        thickness: this.thickness_info
      })
      .then(response => {
        this.availableCountersinks = response.data;
      });
  }

  getColorAccordingToType() {
    if (this.part.on_request_details.partOnRequest()) {
      return 'orange';
    } else {
      return 'primary';
    }
  }

  counts: number[] = this.part.counts;

  deleteFile(part: CustomerPartEntry) {
    this.$store.dispatch('deletePart', part.name);
  }

  numPartsChanged() {
    this.counts = this.counts.map(Number).map((value, index) => {
      if (index == 0) return Math.max(value, 1);
      else return Math.max(value, 0);
    });
    this.updatePartInformation();
  }

  informationChanged(selection: MatThick) {
    this.selectedMaterial = selection.material_name;
    this.thickness_info = selection.thickness_info;
    this.updateAvailabilities();
  }

  addBend(bend: any) {
    this.bends.push(bend);
    this.updatePartInformation();
    if (this.bendInfoNotShown && this.bends.length > this.showDialogAfterNumBends) {
      this.bendInfoDialog = true;
      this.bendInfoNotShown = false;
    }
  }

  deleteBend(index: number) {
    this.bends.splice(index, 1);
    this.updatePartInformation();
    if (this.bends.length <= this.showDialogAfterNumBends) {
      this.bendInfoNotShown = true;
    }
  }

  addCountersink(countersink: any) {
    this.countersinks.push(countersink);
    this.updatePartInformation();
  }

  deleteCountersink(index: number) {
    this.countersinks.splice(index, 1);
    this.updatePartInformation();
  }

  addThread(thread: any) {
    this.threads.push(thread);
    this.updatePartInformation();
  }

  deleteThread(index: number) {
    this.threads.splice(index, 1);
    this.updatePartInformation();
  }

  updatePartInformation() {
    this.$store.dispatch('updatePartOrder', {
      name: this.part.name,
      counts: this.counts,
      material: this.selectedMaterial,
      thickness_info: this.thickness_info,
      bends: this.bends,
      finish: this.finish,
      countersinks: this.countersinks,
      threads: this.threads
    });
  }

  getOnRequestStyle(position: string) {
    if (this.part.on_request_details[`${position}_on_request`]) {
      return 'background-color: orange';
    } else {
      return '';
    }
  }

  mounted() {
    this.updateAvailabilities(false);
  }
}
