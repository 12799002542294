
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import { AnimationUtils } from 'three';
import flattenJSON = AnimationUtils.flattenJSON;

export default Vue.extend({
  data() {
    return {
      dialog: false,
      layers: []
    };
  },
  props: {
    uuid: String,
    name: String
  },
  methods: {
    confirmLayer(index: number): void {
      axios
        .post(`${BACKEND_URL}/confirm/dxf`, {
          layer_number: index,
          uuid: this.uuid
        })
        .then(response => {
          this.$store.commit('updatePartProperties', {
            name: this.name,
            properties: response.data.properties
          });
          this.$store.commit('updateProgress', {
            name: this.name,
            progress: 102
          });
          this.dialog = false;
          this.$store.commit('setLayerDialog', {
            name: this.name,
            layerDialog: false
          });
        })
        .catch(() => {});
    },
    deletePart() {
      this.dialog = false;
      this.$store.dispatch('deletePart', this.name);
    }
  },
  mounted(): void {
    axios
      .get(`${BACKEND_URL}/layers/${this.uuid}`)
      .then(response => {
        this.layers = response.data['layer_data']['layers'];
        if (this.layers.length == 1) {
          this.confirmLayer(0);
        }
      })
      .catch(() => {});
  }
});
