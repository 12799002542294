<template>
  <v-footer color="#CBCBCB" width="100%" class="d-flex align-center justify-space-between">
    <img alt="lastech logo" src="@/assets/lastech_logo.png" class="footer-logo" />
    <div class="text-center copyright">&copy;{{ new Date().getFullYear() }} — <strong>Synapto GmbH</strong></div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer.vue'
};
</script>

<style scoped>
.footer-logo {
  max-height: 73px; /* Adjust as necessary */
  margin-left: 16px; /* Adds some spacing from the left edge */
}

/* Centering the text more accurately */
.copyright {
  flex-grow: 1;
  text-align: center;
  padding-right: 7%; /* Adjust based on your actual logo size to ensure centering */
}
</style>
