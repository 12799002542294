













































import { Component, Prop, Vue } from 'vue-property-decorator';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface HTMLDragEvent extends Event {
  dataTransfer: HTMLInputElement & DragEvent;
}

@Component
export default class UploadCard extends Vue {
  @Prop({ type: Array, required: true })
  acceptedFileTypes!: string[];

  @Prop({ type: String, required: true })
  mainText!: string;

  @Prop({ type: String, required: true })
  subText!: string;

  @Prop({ default: true })
  allowMultiple!: boolean;

  @Prop({ type: Function, required: false })
  clickHandler!: () => void;

  hoverOrDraggingOver: boolean = false;

  get clickHandlerComputed() {
    return this.clickHandler || this.forwardClickToFileInput;
  }

  forwardClickToFileInput() {
    const ref: HTMLInputElement = this.$refs.fileInputRef as HTMLInputElement;
    ref.click();
  }

  onFilesAdded(event: HTMLInputEvent) {
    const files: FileList = event.target.files as FileList;
    this.processFileList(files);
  }

  onClick(event: HTMLInputEvent) {
    event.target.value = '';
  }

  onDrop(event: HTMLDragEvent) {
    const files: FileList = event.dataTransfer.files as FileList;
    this.processFileList(files);
  }

  private processFileList(files: FileList) {
    const filesArray = this.checkFilesAndReturnInArray(files);
    if (filesArray.length > 0) {
      this.$emit('files-added-event', filesArray);
    }
  }

  checkFilesAndReturnInArray(list: FileList): File[] {
    const array: File[] = [];
    list = list as FileList;
    for (let i = 0; i < list.length; i++) {
      const file: File = list.item(i) as File;
      if (this.hasValidExtension(file)) {
        array.push(file);
      } else {
        this.$emit('file-wrong-ext-event', file);
      }
    }
    return array;
  }

  hasValidExtension(file: File): boolean {
    return this.acceptedFileTypes.some(ext => {
      return file.name.toLowerCase().endsWith(ext.toLowerCase());
    });
  }

  get acceptedFileTypesString() {
    return this.acceptedFileTypes.join(',');
  }
}
