




























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import UploadCard from '@/components/util/UploadCard.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcDXFLayerDialog from '@/components/calculator/CalcDXFLayerDialog.vue';
import { createNewPartEntry, CustomerPartEntry } from '@/customer/part_details';
import CalcManual from '@/components/calculator/CalcManual.vue';

interface FileNameToUUIDObj {
  [key: string]: string;
}

@Component({
  components: {
    zaUpload: UploadCard,
    zaDxfDialog: CalcDXFLayerDialog,
    zaCalcManual: CalcManual
  }
})
export default class CalcFileUpload extends Vue {
  dialog = false;
  valid = false;
  currFiles: File[] = [];
  articleRules = [
    (v: string) => !!v || 'Artikelbezeichnung benötigt',
    (v: string) => v.trim().length > 0 || 'Artikelbezeichnung darf nicht leer sein'
  ];

  defaultMessage: string = 'Hochzuladende Dateien hier reinziehen oder mit Klick Dialog öffnen';
  acceptedFiles: string[] = ['.dxf'];

  @Watch('valid')
  onThicknessChange(val: any, oldVal: any) {}

  onFileWrongExt(file: File) {}

  onFilesAdded(files: File[]) {
    this.currFiles = [];

    for (const file of files) {
      if (this.$store.getters.containsFileName(file.name) && this.parts[file.name].error_during_upload) {
        this.deletePart(file.name);
      }
      if (!this.$store.getters.containsFileName(file.name)) {
        let entry: any = {};
        entry['file'] = file;
        entry['article'] = '';
        entry['drawing'] = '';
        this.currFiles.push(entry);
      }
    }

    // this.currFiles = files.filter(file => {
    //   return !this.$store.getters.containsFileName(file.name);
    // });

    this.dialog = true;
  }

  executeUpload() {
    this.dialog = false;
    const alreadyUploadedFiles = Object.keys(this.$store.getters.uploadedParts).length;
    const target = alreadyUploadedFiles + this.currFiles.length;
    let unwatch = this.$store.watch(
      (state, getters) => getters.uploadedParts,
      (newValue, oldValue) => {
        if (Object.keys(newValue).length === target) {
          this.$store.dispatch('calculateOffer');
          unwatch();
        }
      }
    );

    for (let file of this.currFiles) {
      this.uploadFile(file);
    }
  }

  placeholder() {}

  deleteFile(name: string) {
    this.$store.dispatch('deletePart', name);
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.set('file', file.file);
    formData.set('article', file.article);
    formData.set('drawing', file.drawing);
    formData.set('offer_id', this.$store.getters.currentOfferID);
    formData.set('charge_size', this.$store.getters.chargeSize);
    let partEntry: CustomerPartEntry = createNewPartEntry({});
    partEntry.name = file.file.name;
    this.$store.commit('addFile', partEntry);
    axios
      .post(`${BACKEND_URL}/upload/dxf`, formData, {
        onUploadProgress: event => this.onUploadProgress(partEntry.name, event)
      })
      .then(value => {
        this.$store.commit('setPartUUID', {
          uuid: value.data.uuid,
          name: partEntry.name
        });
        this.$store.commit('setLayerDialog', {
          name: partEntry.name,
          layerDialog: true
        });
        this.$store.commit('updateProgress', {
          name: partEntry.name,
          progress: 101
        });
      })
      .catch(() => {
        this.$store.commit('setUploadError', partEntry.name);
      });
  }

  deletePart(name: string) {
    this.$store.commit('deleteFile', name);
  }

  onUploadProgress(file: string, progressEvent: ProgressEvent) {
    this.$store.commit('updateProgress', {
      name: file,
      progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
    });
  }

  get parts() {
    return this.$store.getters.parts;
  }
}
