

































































































































































import { Component, Vue } from 'vue-property-decorator';
import CalcManualDialogGeometry from '@/components/calculator/CalcManualDialogGeometry.vue';
import { createNewPartEntry, CustomerPartEntry } from '@/customer/part_details';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';

@Component({
  components: {
    zaCalcManualDialogGeometry: CalcManualDialogGeometry
  }
})
export default class CalcManualDialog extends Vue {
  type = 'Rechteck';
  geometryDialog = false;
  bendsDialog = false;
  valid = false;
  bends: any[] = [];
  geometries: any[] = [];
  article = '';
  drawing = '';
  rectangular_length = 0;
  rectangular_width = 0;
  circle_diameter_outer = 0;
  circle_diameter_inner = 0;
  articleRules = [
    (v: string) => !!v || 'Artikelbezeichnung benötigt',
    (v: string) =>
      (!!v && v.trim().length > 0) || 'Artikelbezeichnung darf nicht leer sein'
  ];
  lengthRules = [
    (v: number) => !!v || 'Dimension bestimmen',
    (v: number) =>
      (!!v && v <= this.defaults.maxLongLength) ||
      `Maximale Dimension von ${this.defaults.maxLongLength} mm überschritten!`
  ];
  widthRules = [
    (v: number) => !!v || 'Dimension bestimmen',
    (v: number) =>
      (!!v && v <= this.defaults.maxShortLength) ||
      `Maximale Dimension von ${this.defaults.maxShortLength} mm überschritten!`
  ];

  filename: string | null = '';
  file: File | null = null;

  initData() {
    this.geometryDialog = false;
    this.bendsDialog = false;
    this.valid = false;
    this.bends = [];
    this.geometries = [];
  }

  reset() {
    this.initData();
    this.form.resetValidation();
    this.form.reset();
  }

  openGeometryDialog() {
    this.geometryDialog = true;
  }

  deactivateDialogGeometry() {
    this.geometryDialog = false;
  }

  openBendsDialog() {
    this.bendsDialog = true;
  }

  deactivateDialogBends() {
    this.bendsDialog = false;
  }

  deactivateDialog() {
    this.reset();
    this.$emit('close-dialog');
  }

  addBend(payload: any) {
    this.bends.push(payload);
  }

  addInnerGeometry(payload: any) {
    this.geometries.push(payload);
  }

  deleteGeometry(index: any) {
    this.geometries.splice(index, 1);
  }

  deleteBend(index: any) {
    this.bends.splice(index, 1);
  }

  calculateEffectiveArea() {
    let effectiveArea = 0;
    switch (this.type) {
      case 'Rechteck':
        effectiveArea += this.rectangular_length * this.rectangular_width;
        break;
      case 'Rondelle':
        effectiveArea +=
          this.circle_diameter_outer * this.circle_diameter_outer;
        //effectiveArea += Math.PI * (this.circle_diameter_outer / 2) ** 2;
        break;
      case 'Ring':
        effectiveArea +=
          this.circle_diameter_outer * this.circle_diameter_outer;
        //effectiveArea += Math.PI * (this.circle_diameter_outer / 2) ** 2;
        //effectiveArea -= Math.PI * (this.circle_diameter_inner / 2) ** 2;
        break;
    }
    // NOTE customer does not wish to remove inner geometries area
    //effectiveArea -= this.calculateInnerGeometriesAreas();
    return effectiveArea;
  }

  calculateInnerGeometriesAreas() {
    let areaInner = 0;

    for (let geometry of this.geometries) {
      switch (geometry.type) {
        case 'Rechteckig':
          areaInner += geometry.num * (geometry.length * geometry.width);
          break;
        case 'Rund':
          areaInner += geometry.num * (Math.PI * (geometry.diameter / 2) ** 2);
          break;
      }
    }
    // TODO AS WISHED BY CUSTOMER
    return 0;
  }

  calculateCuttingLength() {
    let cuttingLength = 0;
    switch (this.type) {
      case 'Rechteck':
        cuttingLength += 2 * (this.rectangular_length + this.rectangular_width);
        break;
      case 'Rondelle':
        cuttingLength += 2 * Math.PI * (this.circle_diameter_outer / 2);
        break;
      case 'Ring':
        cuttingLength += 2 * Math.PI * (this.circle_diameter_outer / 2);
        cuttingLength += 2 * Math.PI * (this.circle_diameter_inner / 2);
        break;
    }

    cuttingLength += this.calculateInnerGeometriesCuttingLength();
    return cuttingLength;
  }

  calculateInnerGeometriesCuttingLength() {
    let cuttingLengthInner = 0;

    for (let geometry of this.geometries) {
      switch (geometry.type) {
        case 'Rechteckig':
          cuttingLengthInner +=
            geometry.num * (2 * (geometry.length + geometry.width));
          break;
        case 'Rund':
          cuttingLengthInner +=
            geometry.num * (2 * Math.PI * (geometry.diameter / 2));
          break;
      }
    }

    return cuttingLengthInner;
  }

  calculateBendings() {
    let bendings = 0;

    for (let bend of this.bends) {
      bendings += bend.num;
    }

    return bendings;
  }

  calculateSumBendingArcs() {
    let bendingArcs = 0;

    for (let bend of this.bends) {
      bendingArcs += bend.num * bend.bendArc;
    }

    return bendingArcs;
  }

  calculateSumBendingLengths() {
    let bendingLengths = 0;

    for (let bend of this.bends) {
      bendingLengths += bend.num * bend.bendLength;
    }

    return bendingLengths;
  }

  calculateRectangularLength() {
    if (this.type === 'Rechteck') {
      return this.rectangular_length;
    } else {
      return this.circle_diameter_outer;
    }
  }

  calculateRectangularWidth() {
    if (this.type === 'Rechteck') {
      return this.rectangular_width;
    } else {
      return this.circle_diameter_outer;
    }
  }

  countInnerGeometries() {
    const sumNumInnerGeometries = (accumulator: number, currentValue: any) => {
      return accumulator + parseInt(currentValue.num);
    };

    let numInnerGeometries = this.geometries.reduce(sumNumInnerGeometries, 0);
    if (this.type === 'Ring') {
      numInnerGeometries += 1;
    }

    return numInnerGeometries;
  }

  nothing() {}

  onFileChange($event: any) {
    const files = $event.target.files || $event.dataTransfer.files;
    if (files) {
      if (files.length > 0) {
        this.filename = [...files].map(file => file.name).join(', ');
        this.file = files[0];
      } else {
        this.filename = null;
        this.file = null;
      }
    } else {
      this.filename = $event.target.value.split('\\').pop();
    }
    this.$emit('input', this.filename);
  }

  onFocus() {
    let fileInput: any = this.$refs.fileInput;
    fileInput.click();
  }

  addManualPart() {
    // TODO is serialization of geometries and bends needed? Maybe just calculate values needed for formula here and send result!

    if (this.form.validate()) {
      let partEntry: CustomerPartEntry = createNewPartEntry({});
      partEntry.name = this.article;
      this.$store.commit('addFile', partEntry);

      let inner_geometries = this.countInnerGeometries();
      let rectangular_area =
        this.calculateRectangularWidth() * this.calculateRectangularLength();
      this.$store.commit('updatePartProperties', {
        name: this.article,
        properties: {
          rectangular_length: this.calculateRectangularLength(),
          rectangular_width: this.calculateRectangularWidth(),
          inner_geometries: inner_geometries
        }
      });
      const formData = new FormData();
      if (this.file !== null) {
        formData.set('file', this.file);
      }
      formData.set('offer_id', this.$store.getters.currentOfferID);
      formData.set('charge_size', this.$store.getters.chargeSize);
      formData.set('file_name', this.article);
      let part_info: any = {
        contour_length: this.calculateCuttingLength(),
        sum_bending_angles: this.calculateSumBendingArcs(),
        sum_bending_lengths: this.calculateSumBendingLengths(),
        rectangular_area: rectangular_area,
        rectangular_length: this.calculateRectangularLength(),
        rectangular_width: this.calculateRectangularWidth(),
        effective_area: this.calculateEffectiveArea(),
        sum_diameters: 0,
        inner_geometries: inner_geometries,
        bend_lines_count: this.calculateBendings(),
        geometries: JSON.stringify(this.geometries),
        bends: JSON.stringify(this.bends)
      };
      for (let key in part_info) {
        formData.set('_' + key, part_info[key]);
      }

      formData.set('article', this.article);
      formData.set('drawing', this.drawing);
      formData.set('type', this.type);

      axios
        .post(`${BACKEND_URL}/upload/manual`, formData)
        .then(value => {
          this.$store.commit('setPartUUID', {
            uuid: value.data.uuid,
            name: partEntry.name
          });
          this.$store.commit('updateProgress', {
            name: partEntry.name,
            progress: 102
          });
        })
        .catch(() => {
          this.$store.commit('deleteFile', partEntry.name);
        });
      this.reset();
      this.$emit('close-dialog');
    }
  }

  get defaults() {
    return this.$store.getters.defaultValues;
  }

  get form(): Vue & {
    validate: () => boolean;
    reset: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => boolean;
      resetValidation: () => boolean;
    };
  }
}
